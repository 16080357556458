import React from "react"
import { Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FaEnvelope, FaFacebookF, FaPhone } from "react-icons/fa"
import { Link } from "gatsby"

const Contact = () => (
  <Layout pageInfo={{ pageName: "contact", pageTitle: "Contact", bannerTitle: "website-header-06.png" }}>
    <Seo title="Contact"/>

    <Container>
      <section className="general-page" id="contact">
        <Container>

          <Col md={{ span: 8, offset: 2 }}>
            <div className="block">
              <p>
                Alles Kids Kindercoaching
                <br/>
                gevestigd te Steenbergen, Noord-Brabant
              </p>
              <p>
                <a href="tel:+31614627951">
                  <FaPhone/> +31 6 14 62 79 51
                </a>
              </p>
              <p>
                <a href="mailto:info@alleskidskindercoaching.nl">
                  <FaEnvelope/> info@alleskidskindercoaching.nl
                </a>
              </p>
              <p>
                <a href="https://www.facebook.com/alleskidskindercoaching/" aria-label="Vind ons op Facebook"
                   className="facebook">
                  <FaFacebookF/> facebook.com/alleskidskindercoaching
                </a>
              </p>

              <p>
                KvK: 82490651
                <br/>
                BTW-nummer: NL003691129B84
                <br/>
                <br/>
                <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link>.
                <br/>
                AVG: Wij gaan conform de AVG om met persoonsgegevens.
              </p>
            </div>
          </Col>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default Contact
